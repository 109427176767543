<template>
  <div class="search">
    <div class="search-count" v-show="!requestData">共搜索到 {{ totalResult }} 条结果</div>
    <!-- 当搜索类型为all时需要显示切换的tab -->
    <el-tabs
      v-if="$route.query.type === 'all' && !requestData"
      class="search-tab"
      v-model="activeTab"
      @tab-click="switchTab"
    >
      <el-tab-pane name="hospital" v-if="tabDataCount.hospital > 0">
        <span slot="label" class="search-tab-title">医院 ({{ tabDataCount.hospital }})</span>
      </el-tab-pane>
      <el-tab-pane name="hospital-ji" v-if="tabDataCount['hospital-ji'] > 0">
        <span slot="label" class="search-tab-title">
          基层医疗卫生机构 ({{ tabDataCount['hospital-ji'] }})
        </span>
      </el-tab-pane>
      <el-tab-pane name="hospital-pro" v-if="tabDataCount['hospital-pro'] > 0">
        <span slot="label" class="search-tab-title">
          专业公共卫生机构 ({{ tabDataCount['hospital-pro'] }})
        </span>
      </el-tab-pane>
      <el-tab-pane name="hospital-other" v-if="tabDataCount['hospital-other'] > 0">
        <span slot="label" class="search-tab-title">
          其他医疗卫生机构 ({{ tabDataCount['hospital-other'] }})
        </span>
      </el-tab-pane>
      <el-tab-pane name="dealer" v-if="tabDataCount.dealer > 0">
        <span slot="label" class="search-tab-title">经销商 ({{ tabDataCount.dealer }})</span>
      </el-tab-pane>
      <el-tab-pane name="shop" v-if="tabDataCount.shop > 0">
        <span slot="label" class="search-tab-title">药店 ({{ tabDataCount.shop }})</span>
      </el-tab-pane>
      <el-tab-pane name="manufacturer" v-if="tabDataCount.manufacturer > 0">
        <span slot="label" class="search-tab-title">
          生产企业 ({{ tabDataCount.manufacturer }})
        </span>
      </el-tab-pane>
      <el-tab-pane name="medical-machine" v-if="tabDataCount['medical-machine'] > 0">
        <span slot="label" class="search-tab-title">
          医疗器械 ({{ tabDataCount['medical-machine'] }})
        </span>
      </el-tab-pane>
      <el-tab-pane name="other" v-if="tabDataCount.other > 0">
        <span slot="label" class="search-tab-title">其他分类 ({{ tabDataCount.other }})</span>
      </el-tab-pane>
      <el-tab-pane name="doctor" v-if="tabDataCount.doctor > 0">
        <span slot="label" class="search-tab-title">医生 ({{ tabDataCount.doctor }})</span>
      </el-tab-pane>
      <el-tab-pane name="spu" v-if="tabDataCount['spu'] > 0">
        <span slot="label" class="search-tab-title">产品 ({{ tabDataCount['spu'] }})</span>
      </el-tab-pane>
      <el-tab-pane name="sku" v-if="tabDataCount['sku'] > 0">
        <span slot="label" class="search-tab-title">产品(SKU) ({{ tabDataCount['sku'] }})</span>
      </el-tab-pane>
    </el-tabs>

    <!-- 数据量与筛选栏 -->
    <div class="search-tab-container" v-show="!requestData">
      <div class="search-tab-info-fliter" v-if="filterList.length > 0">
        <div v-for="(item, index) in filterList" :key="index" class="search-tab-info-fliter-item">
          {{ getLabel(item.name) }}:{{ item.label }}
          <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
        </div>
        <el-button v-if="filterList.length" @click="deleteAllFilter" type="text">重置</el-button>
      </div>
    </div>

    <!-- <div v-if="!requestData && total === 0" class="search-no-data">
      <img
        src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
        alt="未查询到相关数据"
      />
      未查询到相关数据
    </div> -->

    <div class="listLoading" v-if="requestData">
      <img src="@/assets/loading.gif" alt="loading" />
    </div>

    <div :class="{ 'height-sty': total === 0 }" v-show="!requestData">
      <eyao-table
        class="search-table"
        ref="table"
        :table-data="tableData"
        :total-num="total"
        @filter-change="filterChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <!-- 下拉搜索select暂时使用filters实现 等各个属性的下拉选项列表可以获得之后统一由eyao-table-search-header处理 -->
        <el-table-column
          v-for="column in config"
          :key="`${activeTab}-${column.property}`"
          :prop="column.property"
          :column-key="column.property"
          :width="column.width"
          :min-width="column['min-width']"
          :label="column.title"
          :sortable="column.sort"
          :fixed="column.fixed"
        >
          <template slot="header" slot-scope="scope" :style="{ color: '#667082' }">
            <eyao-table-search-header
              v-if="
                column.search === 'input' ||
                  column.search === 'cascader' ||
                  column.search === 'select' ||
                  column.search === 'autocomplete'
              "
              :property="column.property"
              :form-type="column.search"
              :title="column.title"
              :scope="scope"
              :data="cascaderData[column.property]"
              :options="valueOpts[column.property]"
              :fuzzy-search="apiData"
              @search="search"
            ></eyao-table-search-header>
            <template v-else>{{ column.title }}</template>
          </template>

          <template slot-scope="scope">
            <!-- id字段标蓝 点击后跳转 -->
            <template
              v-if="
                column.property === 'id' ||
                  column.property === 'product_name' ||
                  column.property === 'sku_name'
              "
            >
              <div
                @click="gotoDetail(scope.row.id, scope.row.name)"
                :style="{ color: '#0069FA', cursor: 'pointer' }"
              >
                {{ scope.row[column.property] }}
              </div>
            </template>

            <!-- name字段hover展示hco_code与hcp_code -->
            <template v-else-if="column.property === 'name'">
              <el-popover
                placement="top-start"
                width="160"
                trigger="hover"
                :content="activeTab === 'doctor' ? scope.row.hcp_code : scope.row.hco_code"
              >
                <span
                  slot="reference"
                  :style="{ color: '#0069FA', cursor: 'pointer' }"
                  @click="gotoDetail(scope.row.id, scope.row.name)"
                >
                  {{ scope.row[column.property] }}
                </span>
              </el-popover>
            </template>

            <!-- 所属机构名称跳转详情 -->
            <template v-else-if="column.property === 'hco_name'">
              <div
                @click="gotoHcoName(scope.row.hco_name, scope.row.hco_id)"
                :style="{ color: '#0069FA', cursor: 'pointer' }"
              >
                {{ scope.row[column.property] }}
              </div>
            </template>

            <!-- 涉及时间字段，format值 -->
            <template v-else-if="column.format">
              <template v-if="column.property === 'establishment_date'">
                {{ scope.row[column.property] | getYMD }}
              </template>
              <template v-else>{{ scope.row[column.property] | getFormatTime }}</template>
            </template>

            <template v-else>{{ scope.row[column.property] }}</template>
          </template>
        </el-table-column>

        <!-- 操作列 -->
        <el-table-column width="100" fixed="right" label="操作">
          <div
            slot-scope="scope"
            v-if="getAuthorization('dataList-edit') || getAuthorization('dataList-delete')"
            class="data-list-option"
          >
            <div
              v-if="getAuthorization('dataList-edit')"
              @click="gotoEdit(scope.row.id, scope.row.name)"
            >
              编辑
            </div>
            <div v-if="getAuthorization('dataList-delete')" @click="deleteData(scope.row.id)">
              删除
            </div>
          </div>
        </el-table-column>

        <template v-slot:empty>
          <div class="search-no-data">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>
    </div>

    <!-- 删除窗口 -->
    <el-dialog
      title="确认删除吗"
      :visible.sync="showDeleteDialog"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="deleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="showDeleteDialog = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import store from '@/store';
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import hospitalConfig from '../data-list/config/hospital';
import dealerConfig from '../data-list/config/dealer';
import shopConfig from '../data-list/config/shop';
import instrumentConfig from '../data-list/config/medical-machine';
import enterpriseConfig from '../data-list/config/manufacturer';
import categoryConfig from '../data-list/config/other';
import doctorConfig from '../data-list/config/doctor';
import hospitalLabelConfig from '../data-list/labelConfig/hospital';
import doctorLabelConfig from '../data-list/labelConfig/doctor';
import * as apis from '@/api/data';
import { REQUEST_TYPE, REQUEST_TITLE_TYPE, DICT_TYPE } from '@/utils/constant';
import { getAdminDistrict } from '@/api/cascader-api';
import { dateFormat, getAuthorization } from '@/utils/util';

import spuConfig from '../drug-list/config/drug-spu';
import skuConfig from '../drug-list/config/drug-sku';
import spuLabelConfig from '../drug-list/labelConfig/spu';
import skuLabelConfig from '../drug-list/labelConfig/sku';

export default {
  name: 'Search',
  components: {
    eyaoTable,
    eyaoTableSearchHeader,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
    getYMD(value) {
      if (value && !value.startsWith('0')) {
        const date = new Date(value);
        let year = date.getFullYear();
        year = year < 10 ? `0${year}` : year;
        let month = date.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let day = date.getDate();
        day = day < 10 ? `0${day}` : day;
        return `${year}年${month}月${day}日`;
      }
      return '';
    },
  },
  data() {
    const self = this;
    return {
      // 数据请求
      requestData: true,
      // 表格选用的配置
      config: [],
      configObj: {},
      // 当前激活的tab 控制表格配置
      activeTab: '',
      // 筛选对象与列表
      filterObject: {},
      filterList: [],
      // 引入示例数据
      tableData: [],
      // 删除框的显示 目标id 删除原因
      showDeleteDialog: false,
      deleteDialogTarget: '',
      deleteReason: '',
      // 当前tab的数量
      total: 0,
      // 所有tab的count总数
      totalResult: 0,
      cascaderData: {
        admin_division: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level, value } = node;
            const params = {
              level: `${level}`,
            };
            if (value) {
              params.code = value;
            }
            getAdminDistrict(params).then(res => {
              const nodes = res.district_list.map(item => ({
                value: item.code,
                label: item.name,
                leaf: !item.level,
              }));
              resolve(nodes);
            });
          },
        },
        standard_dept_name: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            const params = {
              code: level === 0 ? '' : node.value.split(',')[0],
              level: level.toString(),
            };
            if (level > 2) {
              return;
            }
            apis.GetStandardDeptList(params).then(res => {
              res.deps.forEach(item => {
                const obj = {
                  value: `${item.code},${item.name}`,
                  label: item.name,
                  leaf: !item.level,
                };
                nodes.push(obj);
              });
              resolve(nodes);
            });
          },
        },
        type_name: {
          lazy: true,
          checkStrictly: true,
          lazyLoad(node, resolve) {
            const { level } = node;
            const nodes = [];
            apis
              .GetOrgTypeList({ level: 2 })
              .then(res => {
                const arr = res.datas.filter(
                  ele => ele.type_name === REQUEST_TITLE_TYPE[self.activeTab],
                );
                const typeId = arr[0]?.type_id;
                return typeId;
              })
              .then(typeId => {
                const params = {
                  parend_ids: level === 0 ? [typeId] : [node.value],
                  level: level + 3,
                };
                if (level > 2) {
                  return;
                }
                apis.GetOrgTypeList(params).then(res => {
                  res.datas.forEach(item => {
                    const obj = {
                      value: item.type_id,
                      label: item.type_name,
                      leaf: !item.is_leaf,
                    };
                    nodes.push(obj);
                  });
                  resolve(nodes);
                });
              });
          },
        },
      },
      valueOpts: {},
      propertyName: [
        'type_name',
        'hospital_level',
        'ownership',
        'is_medical_insurance',
        'business_status',
        'in_doubt',
        'source',
        'gender',
        'hcp_type',
        'hcp_status',
        'standard_dept_name',
        'professional_title',
        'org_type',
        'administrative_title',
        'academic_title',

        'therapeutic_area_name',
        'productline_name',
        'category',
        'is_RX',
        'source',
        'in_doubt',
        'administration',
        'prepared_form',
        'unit',
        'sales_status',
        'is_medical_insurance',
      ],
      administrationList: [], // 行政区划数据
      standardDeptData: [], // 标准科室数据
      typeNameData: [], // 机构类型名数据
      // 表格下拉框的label值
      labelConfig: [],
      currentDataPage: 1,
      pageDataSize: 20,
      // 每个tab的数据量
      tabDataCount: {
        hospital: 0,
        'hospital-ji': 0,
        'hospital-pro': 0,
        'hospital-other': 0,
        dealer: 0,
        shop: 0,
        manufacturer: 0,
        'medical-machine': 0,
        other: 0,
        doctor: 0,
        spu: 0,
        sku: 0,
      },
    };
  },
  computed: {
    // 进入搜索页前判定url的有效性
    validSearch() {
      if (this.$route.query.text === undefined || this.$route.query.type === undefined) {
        return false;
      }
      if (this.$route.query.text === '') {
        return false;
      }
      if (this.$route.query.text.length < 2) {
        return false;
      }
      return true;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'search') {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.validSearch) {
        this.$message.error('无效查询');
        this.$router.push('home');
      } else {
        // 修改面包屑导航
        store.commit('user/setBreadcrumb', [
          {
            name: '搜索结果',
            path: `/dashboard/search?type=${this.$route.query.type}&text=${this.$route.query.text}`,
          },
        ]);
        this.activeTab = this.$route.query.type;
        if (this.activeTab === 'all') this.activeTab = 'hospital';
        this.requestData = true;
        this.tabDataCount = null;
        this.requestSearchCount();
        this.switchTab();
      }
    },

    // 操作权限
    getAuthorization,

    // 请求每个tab数据条数
    requestSearchCount(flag = true) {
      const params = [];
      Object.keys(REQUEST_TITLE_TYPE).forEach(key => {
        if (key === 'mid-org' || key === 'mid-doc') return;
        params.push({
          type2type: REQUEST_TITLE_TYPE[key],
          conditions: this.searchMainDataOption(REQUEST_TITLE_TYPE[key]),
        });
      });

      apis
        .SearchAllCount({ options: params })
        .then(res => {
          console.log(res);
          if (res?.search_result) {
            const counts = JSON.parse(res.search_result);
            this.tabDataCount = {
              hospital: counts['医院'],
              'hospital-ji': counts['基层医疗卫生机构'],
              'hospital-pro': counts['专业公共卫生机构'],
              'hospital-other': counts['其他医疗卫生机构'],
              dealer: counts['经销商'],
              shop: counts['药店'],
              manufacturer: counts['生产企业'],
              'medical-machine': counts['医疗器械'],
              other: counts['其他分类'],
              doctor: counts['医生'],
              spu: counts['产品'],
              sku: counts['产品(SKU)'],
            };
            this.totalResult = counts['总数'];
            console.log(this.tabDataCount);

            // 如果医院没有数据，其他机构有，则重新调用一次接口
            if (this.tabDataCount?.hospital === 0 && this.totalResult > 0) {
              console.log(11111);
              const firstTab = [];
              Object.keys(this.tabDataCount).forEach(key => {
                if (this.tabDataCount[key] > 0) {
                  firstTab.push(key);
                }
              });
              console.log(firstTab);
              this.activeTab = firstTab[0];
              this.switchTab();
            }

            if (flag) {
              const params = {
                behavior_type: 2,
                target_content: this.$route.query.text,
                target_content_type: '',
                target_content_name: '',
              };
              apis.DataReport(params).then(() => {
                console.log('数据上报---');
              });
            }
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
        });
    },

    // 切换tab时更改表格的配置 包括列名 是否可编辑等等
    switchTab() {
      console.log(this.activeTab);
      switch (this.activeTab) {
        case 'hospital':
        case 'hospital-ji':
        case 'hospital-pro':
        case 'hospital-other':
          this.config = hospitalConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'dealer':
          this.config = dealerConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'shop':
          this.config = shopConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'medical-machine':
          this.config = instrumentConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'manufacturer':
          this.config = enterpriseConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'other':
          this.config = categoryConfig;
          this.labelConfig = hospitalLabelConfig;
          break;
        case 'doctor':
          this.config = doctorConfig;
          this.labelConfig = doctorLabelConfig;
          break;
        case 'spu':
          this.config = spuConfig;
          this.labelConfig = spuLabelConfig;
          break;
        case 'sku':
          this.config = skuConfig;
          this.labelConfig = skuLabelConfig;
          break;
        default:
          break;
      }

      this.config.forEach(item => {
        this.configObj[item.property] = item;
      });

      this.filterInit();
      // this.searchData();
      this.searchMainData();
      this.getOptions();
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage()[this.activeTab] || {};
      this.updateFilterList();
    },

    // 每次通过table组件原生调整筛选时更新筛选条件信息
    filterChange(filter) {
      Object.keys(filter).forEach(key => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      // this.searchData();
      this.searchMainData();
    },

    // 根据filterObject调整筛选条件列表
    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach(key => {
        if (JSON.stringify(this.filterObject[key]) !== '{}') {
          if (key === 'admin_division') {
            // 行政区划
            list.push({ name: key, label: this.filterObject.admin_division.labelList });
          } else if (key === 'type_name') {
            // 类型细分
            list.push({ name: key, label: this.filterObject.type_name.labelList });
          } else if (key === 'standard_dept_name') {
            // 标准科室
            list.push({ name: key, label: this.filterObject.standard_dept_name.labelList });
          } else if (this.filterObject[key].label) {
            list.push({ name: key, label: this.filterObject[key].label });
          }
        }
      });
      this.filterList = list;
      console.log(this.filterList, '筛选条件list');
      this.setStorage();
    },

    // 筛选条件被手动删除时调用table方法更新table内筛选信息
    deleteFilterItem(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      // this.searchData();
      this.searchMainData();
    },

    // 重置所有筛选条件
    deleteAllFilter() {
      localStorage.removeItem('g-data-filter');
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      setTimeout(() => {
        // this.searchData();
        this.searchMainData();
      });
    },

    search(property, val, formType, label) {
      console.log(property, val);

      let reqType = 2;
      const propertyArr = ['professional_title', 'hcp_type', 'type_name'];

      if (formType === 'cascader' || formType === 'select') {
        if (propertyArr.includes(property)) {
          reqType = 1;
        }
        if (property === 'admin_division') {
          const area = ['province_name', 'city_name', 'district_name', 'town_name', 'village_name'];
          const labelList = label.join('/');
          const adminDivision = {};
          const areaObj = {};
          val.forEach((ele, index) => {
            areaObj[area[index]] = { label: label[index], value: label[index], formType, type: 1 };
          });
          console.log(areaObj);
          adminDivision.admin_division = areaObj;
          adminDivision.admin_division.labelList = labelList;
          this.filterChange(adminDivision);
        } else if (property === 'standard_dept_name') {
          const standardName = [
            'standard_dept_name1',
            'standard_dept_name2',
            'standard_dept_name3',
          ];
          const labelList = label.join('/');
          const standardDept = {};
          const obj = {};
          val.forEach((ele, index) => {
            obj[standardName[index]] = {
              label: label[index],
              value: label[index],
              formType,
              type: 1,
            };
          });
          standardDept.standard_dept_name = obj;
          standardDept.standard_dept_name.labelList = labelList;
          console.log(standardDept);
          this.filterChange(standardDept);
        } else if (property === 'type_name') {
          const typeNameArr = ['hco_type_name3', 'hco_type_name4', 'hco_type_name5'];
          const labelList = label.join('/');
          const typeNames = {};
          const obj = {};
          val.forEach((ele, index) => {
            obj[typeNameArr[index]] = {
              label: label[index],
              value: label[index],
              formType,
              type: 1,
            };
          });
          typeNames.type_name = obj;
          typeNames.type_name.labelList = labelList;
          console.log(typeNames);
          this.filterChange(typeNames);
        } else {
          // 是否医保、是否存疑、性别的type为9
          const speciaType = ['is_medical_insurance', 'in_doubt', 'gender'];
          if (speciaType.includes(property)) reqType = 9;
          this.filterChange({ [property]: { label, value: val, formType, type: reqType } });
        }
      } else if (formType === 'input' || formType === 'autocomplete') {
        this.filterChange({ [property]: { label: val, value: val, type: 1 } });
      }
    },

    gotoDetail(id, name) {
      if (this.activeTab !== 'spu' && this.activeTab !== 'sku') {
        const routerData = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: { id, type: 'preview', source: this.activeTab, name },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      } else {
        const routerData = this.$router.resolve({
          path: '/dashboard/drug-detail',
          query: { id, type: 'preview', source: this.activeTab },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      }
    },

    // 所属机构名称跳转详情
    gotoHcoName(hcoName, hcoId) {
      const req = {
        obj_name: 't_hco',
        data_id: hcoId,
        fields: ['hco_type'],
      };
      apis
        .GetMainDataDetail(req)
        .then(res => {
          if (res?.data_detail) {
            const hcoType = JSON.parse(res.data_detail).hco_type;
            let source = '';
            Object.keys(REQUEST_TITLE_TYPE).forEach(ele => {
              if (REQUEST_TITLE_TYPE[ele] === hcoType) {
                source = ele;
              }
            });
            const routerData = this.$router.resolve({
              path: '/dashboard/data-detail',
              query: {
                id: hcoId,
                name: hcoName,
                type: 'preview',
                source,
              },
            });
            // console.log(decodeURIComponent(routerData.href));
            window.open(decodeURIComponent(routerData.href), '_blank');
          }
        })
        .catch(() => {
          this.$message.error('查不到数据，该数据可能已经被删除');
        });
    },

    gotoEdit(id, name) {
      console.log(this.activeTab);
      if (this.activeTab !== 'spu' && this.activeTab !== 'sku') {
        const routerData = this.$router.resolve({
          path: '/dashboard/data-detail',
          query: { id, type: 'edit', source: this.activeTab, name },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      } else {
        const routerData = this.$router.resolve({
          path: '/dashboard/drug-detail',
          query: { id, type: 'edit', source: this.activeTab },
        });
        window.open(decodeURIComponent(routerData.href), '_blank');
      }
    },
    // 将删除数据的id记录后弹框
    deleteData(id) {
      this.deleteDialogTarget = id;
      this.showDeleteDialog = true;
    },
    // 确认删除
    deleteConfirm() {
      console.log('delete', this.deleteDialogTarget, this.deleteReason);
      const params = {
        obj_name: REQUEST_TYPE[this.activeTab],
        data_id: this.deleteDialogTarget,
        reason: this.deleteReason,
      };
      apis.DeleteMainDataDetail(params).then(res => {
        console.log(res, '-------');
        this.$message.success('删除成功');
        this.showDeleteDialog = false;
        this.deleteDialogTarget = '';
        this.deleteReason = '';
        setTimeout(() => {
          // this.searchData();
          this.searchMainData();
          this.requestSearchCount(false);
        }, 500);
      });
    },

    getSearchCondition() {
      const optKeys = {};
      const specialKey = ['admin_division', 'type_name', 'standard_dept_name'];
      Object.keys(this.filterObject).forEach(ele => {
        if (JSON.stringify(this.filterObject[ele]) !== '{}') {
          if (!specialKey.includes(ele) && ele !== 'keyword') {
            optKeys[ele] = this.filterObject[ele];
          } else {
            const obj = this.filterObject[ele];
            Object.keys(obj).forEach(e => {
              if (e !== 'labelList') {
                optKeys[e] = obj[e];
              }
            });
          }
        }
      });

      const searchOption = [];
      if (JSON.stringify(optKeys) !== '{}') {
        Object.keys(optKeys).forEach(key => {
          searchOption.push({
            field_name: key,
            type: Number(optKeys[key].type),
            keywords: [optKeys[key].value],
          });
        });
      }
      return searchOption;
    },

    searchMainDataOption(moreType = '') {
      const dataOption = [];
      const hcoFields = [
        'name',
        'hco_type_name1',
        'hco_type_name2',
        'hco_type_name3',
        'hco_type_name4',
        'hco_type_name5',
        'parent_hco',
        'nhc_name',
        'former_name',
        'alias_name',
        'province_name',
        'city_name',
        'district_name',
        'town_name',
        'village_name',
        'address',
      ];
      const hcpFields = [
        'name',
        'pinyin',
        'hco_name',
        'listed_dept_name',
        'standard_dept_name1',
        'standard_dept_name2',
        'standard_dept_name3',
        'nhc_professional_type',
        'nhc_location',
        'nhc_scope',
      ];
      const spu = [
        'therapeutic_area_code',
        'therapeutic_area_name',
        'productline_code',
        'productline_name',
        'product_name',
        'product_eng_name',
        'trade_name',
        'trade_eng_name',
        'common_name',
        'common_eng_name',
      ];
      const sku = ['sku_code', 'sku_name', 'sku_eng_name', 'product_code', 'product_name'];

      if (moreType === '医生') {
        hcpFields.forEach(ele => {
          dataOption.push({
            field_name: ele,
            type: 1,
            keywords: [`${this.$route.query.text}`],
          });
        });
      } else if (moreType === '产品') {
        spu.forEach(ele => {
          dataOption.push({
            field_name: ele,
            type: 1,
            keywords: [`${this.$route.query.text}`],
          });
        });
      } else if (moreType === '产品(SKU)') {
        sku.forEach(ele => {
          dataOption.push({
            field_name: ele,
            type: 1,
            keywords: [`${this.$route.query.text}`],
          });
        });
      } else {
        hcoFields.forEach(ele => {
          dataOption.push({
            field_name: ele,
            type: 1,
            keywords: [`${this.$route.query.text}`],
          });
        });
      }

      const dataCondition = {
        children_flag: 2, // 1-and, 2-or
        children: dataOption,
      };
      const firstCondition = {
        field_name: 'hco_type',
        type: 2,
        keywords: moreType ? [moreType] : [REQUEST_TITLE_TYPE[this.activeTab]],
      };

      const searchCondis = this.getSearchCondition();
      if (moreType && moreType === '医生') return [dataCondition, ...searchCondis];
      if (moreType && (moreType === '产品' || moreType === '产品(SKU)'))
        return [dataCondition, ...searchCondis];
      return [firstCondition, dataCondition, ...searchCondis];
    },

    searchMainData() {
      this.requestData = true;
      let condis = [];
      if (this.activeTab === 'doctor') {
        condis = this.searchMainDataOption('医生');
      } else if (this.activeTab === 'spu') {
        condis = this.searchMainDataOption('产品');
      } else if (this.activeTab === 'sku') {
        condis = this.searchMainDataOption('产品(SKU)');
      } else {
        condis = this.searchMainDataOption();
      }
      const params = {
        main_data: REQUEST_TYPE[this.activeTab],
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        fields: this.dealDivisionParam(),
        conditions: condis,
      };
      console.log(params);
      apis
        .SearchMainData(params)
        .then(res => {
          console.log(res);
          if (res && res.data) {
            const dataList = res.data.map(item => JSON.parse(item));
            this.total = res.total_num;

            if (this.activeTab === 'doctor') {
              // 处理标准科室
              this.dealStandardData(dataList);
            } else {
              // 处理行政区划
              this.dealAdminData(dataList);
              // 处理机构类型名
              this.dealTypeNameData(dataList);
            }

            this.dealDataLabel(dataList);
            this.tableData = dataList;
            console.log(this.tableData, 'tableData');
            this.requestData = false;
          }
        })
        .catch(err => {
          this.$message.error(`${err.message}`);
        });
    },

    getSearchOption() {
      console.log(this.filterObject, 'search filterObject');
      const optKeys = {};
      const specialKey = ['admin_division', 'type_name', 'standard_dept_name'];
      Object.keys(this.filterObject).forEach(ele => {
        if (JSON.stringify(this.filterObject[ele]) !== '{}') {
          if (!specialKey.includes(ele) && ele !== 'keyword') {
            optKeys[ele] = this.filterObject[ele];
          } else {
            const obj = this.filterObject[ele];
            Object.keys(obj).forEach(e => {
              if (e !== 'labelList') {
                optKeys[e] = obj[e];
              }
            });
          }
        }
      });
      const searchOption = [];
      if (JSON.stringify(optKeys) !== '{}') {
        Object.keys(optKeys).forEach(key => {
          searchOption.push({
            field_name: key, // 字段名称
            data_name: REQUEST_TYPE[this.activeTab], // 表名
            type: Number(optKeys[key].type), // 筛选类型； 1-包含，2-等于
            key: optKeys[key].value,
          });
        });
      }
      console.log(searchOption, 'searchOption');
      return searchOption;
    },

    searchData() {
      this.requestData = true;
      const searchOption = this.getSearchOption();
      const params = {
        main_data: REQUEST_TYPE[this.activeTab],
        data: REQUEST_TITLE_TYPE[this.activeTab],
        keyword: this.$route.query.text,
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        search_fields: this.dealDivisionParam(),
        SearchOptions: searchOption,
      };
      apis
        .SearchMainDataByType(params)
        .then(res => {
          if (res && res.data) {
            const dataList = res.data.map(item => JSON.parse(item));
            this.total = res.total_num;

            if (this.activeTab === 'doctor') {
              // 处理标准科室
              this.dealStandardData(dataList);
            } else {
              // 处理行政区划
              this.dealAdminData(dataList);
              // 处理机构类型名
              this.dealTypeNameData(dataList);
            }

            this.dealDataLabel(dataList);
            this.tableData = dataList;
            console.log(this.tableData, 'tableData');
            this.requestData = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.$message.error(`${err.message}`);
          this.requestData = false;
        });
    },

    // 处理请求字段
    dealDivisionParam() {
      let allProperty = this.config.map(item => item.property);
      // 医生，标准科室
      if (this.activeTab === 'doctor') {
        const arr = [
          'standard_dept1',
          'standard_dept2',
          'standard_dept3',
          'standard_dept_name1',
          'standard_dept_name2',
          'standard_dept_name3',
        ];
        allProperty = [...allProperty, ...arr, 'id', 'hcp_code', 'hco_id'];
      }

      // 机构，行政区划、二级分类、类型细分
      if (this.activeTab !== 'doctor') {
        const arr = [
          'province_name',
          'city_name',
          'district_name',
          'town_name',
          'village_name',
          'province_code',
          'city_code',
          'district_code',
          'town_code',
          'village_code',
        ];
        const hcoTypeArr = [
          'hco_type1',
          'hco_type_name1',
          'hco_type2',
          'hco_type_name2',
          'hco_type3',
          'hco_type_name3',
          'hco_type4',
          'hco_type_name4',
          'hco_type5',
          'hco_type_name5',
        ];
        allProperty = [...allProperty, ...hcoTypeArr, ...arr, 'id', 'hco_code'];
      }

      return allProperty;
    },

    dealAdminData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        arr.push({
          id: ele.id,
          province_name: ele.province_name,
          city_name: ele.city_name,
          district_name: ele.district_name,
          town_name: ele.town_name,
          village_name: ele.village_name,
          province_code: ele.province_code,
          city_code: ele.city_code,
          district_code: ele.district_code,
          town_code: ele.town_code,
          village_code: ele.village_code,
        });
        const adminDivision = `${ele.province_name}/${ele.city_name}/${ele.district_name}/${ele.town_name}/${ele.village_name}`;
        ele.admin_division = this.delEndStr(adminDivision);
      });
      this.administrationList = arr;
    },

    dealStandardData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
        const standardName1 = ele.standard_dept_name1 ? ele.standard_dept_name1 : '';
        const standardName2 = ele.standard_dept_name2 ? ele.standard_dept_name2 : '';
        const standardName3 = ele.standard_dept_name3 ? ele.standard_dept_name3 : '';
        arr.push({
          id: ele.id,
          standard_dept1: ele.standard_dept1,
          standard_dept2: ele.standard_dept2,
          standard_dept3: ele.standard_dept3,
          standard_dept_name1: standardName1,
          standard_dept_name2: standardName2,
          standard_dept_name3: standardName3,
        });
        const standardName = `${standardName1}/${standardName2}/${standardName3}`;
        ele.standard_dept_name = this.delEndStr(standardName);
      });
      this.standardDeptData = arr;
    },

    dealTypeNameData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        arr.push({
          id: ele.id,
          hco_type1: ele.hco_type1,
          hco_type_name1: ele.hco_type_name1,
          hco_type2: ele.hco_type2,
          hco_type_name2: ele.hco_type_name2,
          hco_type3: ele.hco_type3,
          hco_type_name3: ele.hco_type_name3,
          hco_type4: ele.hco_type4,
          hco_type_name4: ele.hco_type_name4,
          hco_type5: ele.hco_type5,
          hco_type_name5: ele.hco_type_name5,
        });
        // const typeName = `${ele.hco_type_name3}/${ele.hco_type_name4}/${ele.hco_type_name5}`;
        // ele.type_name = this.delEndStr(typeName);
        ele.hco_type = ele.hco_type_name2;
      });
      this.typeNameData = arr;
    },

    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const propertyArr = ['standard_dept_name', 'type_name'];
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          // if (key === 'professional_title' || propertyArr.includes(key)) {
          //   return;
          // }
          if (propertyArr.includes(key)) {
            return;
          }
          if (this.propertyName.includes(key)) {
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.labelConfig.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    // 获取下拉选项列表
    getOptions() {
      const optionsApi = [];
      const opts = {};
      this.config.forEach(item => {
        if (item.search === 'select') {
          let params = {
            dict_api_name: item.property,
            dict_type: DICT_TYPE[this.activeTab],
          };
          if (item.property === 'source') {
            params = {
              dict_api_name: 'source',
              dict_type: '4',
            };
          }
          if (item.property === 'in_doubt') {
            params = {
              dict_api_name: 'in_doubt',
              dict_type: '0',
            };
          }

          if (item.property === 'productline_name') {
            params = {
              dict_api_name: 'productline_code',
              dict_type: DICT_TYPE[this.activeTab],
            };
          }
          if (item.property === 'therapeutic_area_name') {
            params = {
              dict_api_name: 'therapeutic_area_code',
              dict_type: DICT_TYPE[this.activeTab],
            };
          }
          const retName = ['org_type', 'type_name'];
          if (retName.includes(item.property)) return;

          optionsApi.push(
            apis.GetDictOptionList(params).then(res => {
              opts[item.property] = res.dict_list.map(opt => {
                return {
                  text: opt.label,
                  label: opt.label,
                  value: opt.code,
                };
              });
              return true;
            }),
          );
        }
      });
      Promise.all(optionsApi).then(() => {
        // console.log(res);
        Object.keys(opts).forEach(key => {
          this.$set(this.valueOpts, key, opts[key]);
        });
      });
    },

    // 获取机构类型名数据
    GetOrgTypeLists() {
      const arr = [
        { queryName: 'hospital', name: '医院', code: '201' },
        { queryName: 'hospital-ji', name: '基层医疗卫生机构', code: '202' },
        { queryName: 'hospital-pro', name: '专业公共卫生机构', code: '203' },
        { queryName: 'hospital-other', name: '其他医疗卫生机构', code: '204' },
        { queryName: 'dealer', name: '经销商', code: '101' },
        { queryName: 'shop', name: '药店', code: '102' },
      ];
      let type2 = '201';
      arr.forEach(ele => {
        if (ele.queryName === this.activeTab) {
          type2 = ele.code;
        }
      });
      const params = [
        { field: 'level', values: ['5'] },
        { field: 'type2', values: [type2] },
        { field: 'is_deleted', values: ['2'] },
      ];

      apis
        .GetOrgTypeList({ filters: params })
        .then(res => {
          if (res && res.datas) {
            const data = JSON.parse(res.datas);
            let list = data.map(item => {
              return { label: item.name, value: item.type5 };
            });
            const obj = {};
            // 防止出现相同数据
            list = list.reduce((item, next) => {
              obj[next.value] ? '' : (obj[next.value] = true && item.push(next));
              return item;
            }, []);
            // console.log(list);
            this.$set(this.valueOpts, 'type_name', list);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    setStorage() {
      const filterData = this.getStorage();
      filterData[this.activeTab] = this.filterObject;
      localStorage.setItem('g-data-filter', JSON.stringify(filterData));
    },
    getStorage() {
      const str = localStorage.getItem('g-data-filter');
      return str ? JSON.parse(str) : {};
    },

    apiData(queryString, property) {
      // 接口通过
      console.log('queryString', queryString);

      const params = {
        dict_api_name: property,
        dict_type: DICT_TYPE[this.activeTab],
      };
      const createFilter = queryString => {
        return restaurant => {
          return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
      };
      return apis.GetDictOptionList(params).then(res => {
        console.log(res, 'GetDictOptionList');
        const restaurants = res.map(item => ({
          value: item.label,
        }));
        const results = queryString ? restaurants.filter(createFilter(queryString)) : restaurants;
        return results;
      });
    },

    getLabel(property) {
      if (property === 'keyword') return '关键字';
      if (property === 'admin_division') return '行政区划';
      return this.configObj[property].title;
    },

    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      // this.searchData();
      this.searchMainData();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      // this.searchData();
      this.searchMainData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import './search.scss';
</style>
