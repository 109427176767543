import { GetOrgType, GetAdminDistrict } from '@/api/data';

let orgTypt;
let orgTypeNotLoaded = true;
let adminDistrict;
let adminDistrictNotLoaded = true;
const getOrgType = params => {
  if (orgTypeNotLoaded) {
    orgTypeNotLoaded = false;
    return GetOrgType(params).then(res => {
      orgTypt = res;
      return orgTypt;
    });
  }
  if (params.level !== '0') {
    return GetOrgType(params);
  }
  return new Promise(resolve => {
    if (orgTypt) {
      resolve(orgTypt);
    } else {
      setTimeout(() => {
        resolve(getOrgType(params));
      }, 50);
    }
  });
};

const getAdminDistrict = params => {
  if (adminDistrictNotLoaded) {
    adminDistrictNotLoaded = false;
    return GetAdminDistrict(params).then(res => {
      adminDistrict = res;
      return adminDistrict;
    });
  }
  if (params.level !== '0') {
    return GetAdminDistrict(params);
  }
  return new Promise(resolve => {
    if (adminDistrict) {
      resolve(adminDistrict);
    }
    setTimeout(() => {
      resolve(getAdminDistrict(params));
    }, 50);
  });
};

export { getOrgType, getAdminDistrict };
